import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { Router, TitleStrategy, provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HttpLoaderFactory, I18NPageTitleStrategy, waitFori18nextToLoad } from '@core/utility/i18n-utils';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { GlobalErrorHandler } from '@shared/errorhandlers/global-error-handler';
import { NewAzureAccessTokenInterceptor } from '@shared/interceptors/new-azure-access-token.interceptor';
import { XsrfInterceptor } from '@shared/interceptors/xsrf.interceptor';
import { ENV } from '@env/environment';

import * as Sentry from "@sentry/angular-ivy";
import {  TraceService } from '@sentry/angular-ivy';
declare global {
  interface Window {
    tsParticles: any;
    TrelloBoards:any;
    Calendly:any
  }
}

if(!["DEV","TEST","DOCKER_DEV"].includes(ENV.type)){
  Sentry.init({
    environment:"Angular_"+ENV.type.toUpperCase(),
    dsn: "https://3fd56ab3c04247bca1cd68e401bd46b6@o4505122556215296.ingest.sentry.io/4505122560999424",
    integrations: [
      Sentry.browserTracingIntegration({
        tracePropagationTargets: ["localhost", "https://windmillcode.com","https://ui.preview.windmillcode.com"],
      }),
    ],
    // beforeSend

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: ["DEV","TEST","DOCKER_DEV"].includes(ENV.type)? 0.0: 1.0,
  });

}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps:[HttpClient]
        }
      }),
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: waitFori18nextToLoad,
      deps: [TranslateService],
      multi: true
    },
    {provide:HTTP_INTERCEPTORS,useClass:NewAzureAccessTokenInterceptor,multi:true},
    {provide:HTTP_INTERCEPTORS,useClass:XsrfInterceptor,multi:true},
    {provide:TitleStrategy,useClass:I18NPageTitleStrategy },


    // Sentry
    {
      provide: ErrorHandler,
      useFactory:()=> new GlobalErrorHandler({
        showDialog: true
      }),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    {
      provide: TraceService,
      deps: [Router],
    }
  ]
};
