// angular
import { ChangeDetectorRef, Component, HostBinding, ViewContainerRef } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';

// rxjs
import { Subject, catchError, merge, takeUntil, tap, throwError } from 'rxjs';

// services
import { BaseService } from '@core/base/base.service';

// misc
import { ENV } from '@env/environment';
import { UtilityService } from '@core/utility/utility.service';


// wml-components
import { HttpClient } from '@angular/common/http';
import { WmlNotifyService } from '@windmillcode/angular-wml-notify';
import { generateClassPrefix } from '@windmillcode/angular-wml-components-base';
import { SharedModule } from '@shared/shared.module';
import { AccountsService } from '@shared/services/accounts/accounts.service';
import { NavService } from '@shared/services/nav/nav.service';
import { StoreService } from '@shared/services/store/store.service';
import { CantReachBackendError } from '@core/utility/error-utils';
import { SITE_OFFLINE_ENUM, toggleDarkMode } from '@core/utility/common-utils';
import { restoreOriginalConsoleObject } from '@core/utility/env-utils';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    SharedModule
  ],
  providers:[

  ]

  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent  {
  constructor(
    public baseService: BaseService,
    public utilService: UtilityService,
    public cdref: ChangeDetectorRef,
    public vcf: ViewContainerRef,
    public router:Router,
    public http:HttpClient,
    public wmlNotifyService:WmlNotifyService,
    public navService:NavService,
    public storeService:StoreService,
    public accountsService:AccountsService
  ) {
    this.listenForChangesOutSideChangeDetection().subscribe()
  }

  classPrefix = generateClassPrefix(ENV.classPrefix.app)
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  ngUnsub = new Subject<void>()


  listenForChangesOutSideChangeDetection = ()=>{
    return merge(
      this.baseService.popupParams.togglePopupSubj,
      this.baseService.toggleOverlayLoadingSubj,
    )
    .pipe(
      takeUntil(this.ngUnsub),
      tap(()=>{
        this.cdref.detectChanges()
      })
    )

  }

  removeAngularIdentifiers() {
    if (!["DEV","TEST"].includes(ENV.type)) {
      this.vcf.element.nativeElement.removeAttribute('ng-version');
    }
  }

  handleSiteNavigation = ()=>{
    if (ENV.app.siteOffline === SITE_OFFLINE_ENUM.TRUE) {

      this.router.navigateByUrl(ENV.nav.urls.siteOffline);
      return false
    } else if (this.utilService.getWindow().location.pathname === ENV.nav.urls.siteOffline) {
      this.router.navigateByUrl(ENV.nav.urls.home);
      return true
    }
    return true
  }

  checkIfBackendIsWorking() {
    return this.http
      .get(ENV.app.backendHealthCheck())
      .pipe(
        takeUntil(this.ngUnsub),
        catchError((err) => throwError(() => {
          if (!['DEV','TEST','DOCKER_DEV'].includes(ENV.type)) {
            this.router.navigateByUrl(ENV.nav.urls.siteOffline);
          }
          return new CantReachBackendError(err);
        }))
      );
  }

  listenForVisitedLinks() {
    return this.navService.collectVisitedLinks()
      .pipe(
        takeUntil(this.ngUnsub)
      )

  }

  doMiscConfigs() {
    this.removeAngularIdentifiers();
    let myContinue = this.handleSiteNavigation()
    if(myContinue){
      this.baseService.appCdRef = this.cdref;
      ENV.nav.urls.initialURL = window.location.pathname;
      this.checkIfBackendIsWorking().subscribe();
      this.listenForVisitedLinks().subscribe();
      this.storeService.clickToggleUseAIPricingBtn(true)
      this.accountsService.manageUsersLoginInfo();
    }

  }

  ngOnInit() {
    this.doMiscConfigs()
    toggleDarkMode(true)
  }

  ngAfterViewInit (){
    // this.baseService.createWMLNote("Partial system maintenance is currently being performed, some or all features may not work as expected")
    restoreOriginalConsoleObject()
    this.cdref.detectChanges()
  }
  ngOnDestroy() {
    this.ngUnsub.next()
    this.ngUnsub.complete()
  }

}
