<router-outlet ></router-outlet>
<wml-notify id="NotifyBanner" [class]="classPrefix('MainNotify0')"></wml-notify>
<div
*ngIf="baseService.toggleOverlayLoadingSubj$ | async"
 [class]="classPrefix('Pod1')">
  <overlay-loading [class]="classPrefix('MainLoading0')" ></overlay-loading>
</div>

<wml-mobile-nav-zero
[params]="navService.mobileParams"></wml-mobile-nav-zero>
<wml-panel [params]="storeService.cartPanelParams"></wml-panel>
<div *ngIf="baseService.popupParams.togglePopupSubj | async" [class]="classPrefix('Pod0')">
  <wml-popup [params]="baseService.popupParams"></wml-popup>
</div>
