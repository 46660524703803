import { Inject, inject, Injectable } from '@angular/core';
import { BaseService } from '@core/base/base.service';
import { WmlNotifyBarType } from '@windmillcode/angular-wml-notify';
import { SentryErrorHandler,ErrorHandlerOptions } from '@sentry/angular-ivy';
import { ENV } from '@env/environment';
import { UtilityService } from '@core/utility/utility.service';


export class SentryErrorHandlerOptions implements ErrorHandlerOptions{

}
@Injectable()
export class GlobalErrorHandler extends SentryErrorHandler {
  constructor(
    @Inject(SentryErrorHandlerOptions) public configs:ErrorHandlerOptions,
  ) {
    super(configs)
    this.baseService =  inject(BaseService);
    this.utilService = inject(UtilityService)
  }
  public baseService
  public utilService
  override handleError(error:Error) {

    if([
      "SquareTokenizeError",
      "AuthInvalidPasswordResetCode",
      "AuthWrongPassword",
      "UserCanceledAuthError",
      "CantReachBackendError",
      "AuthEmailAlreadyInUse",
      "AuthUserNotFound"
    ].find((err)=>err === error.name) ){
      return
    }

    if([
      "ServiceUnavailableError"
    ].find((err)=>err === error.name) ){
      this.baseService.createWMLNote('global.serviceUnavailableError',WmlNotifyBarType.Error);
      return
    }
    else if(error.message.includes("ChunkLoadError: Loading chunk")){

      this.utilService.getWindow().location.reload()
      return
    }
    else{
      this.baseService.createWMLNote('global.systemError',WmlNotifyBarType.Error);

      if(["DOCKER_DEV","DEV","TEST"].includes(ENV.type)){
        super.handleError(error);
      }
      else{
        console.error(error)
      }
    }
    this.handleError = ()=>{}
  }


}
